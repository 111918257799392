import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container top-container" }
const _hoisted_3 = { class: "container middle-container" }
const _hoisted_4 = { class: "container bottom-container" }

import {getCurrentInstance, onMounted, ref} from "vue";
import {MainButton, useWebApp, useWebAppHapticFeedback, useWebAppTheme, useWebAppViewport} from "vue-tg";
import TabHeaderComponent from "@/components/home/tab/TabHeaderComponent.vue";
import TabContentComponent from "@/components/home/tab/TabContentComponent.vue";
import ImageUploadComponent from "@/components/home/input/ImageUploadComponent.vue";
import {Analytics} from "@/analytics/Analytics";
import LoadingOverlayComponent from "@/components/home/LoadingOverlayComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const theme = useWebAppTheme().colorScheme;
const selectedTab = ref(0);
useWebAppViewport().expand();

const formDataObject = ref({
  images: [] as string[],
  location: '',
  price: 0,
  description: '',
  isSell: true
});

const hardValidate = ref(false);
const isUploading = ref(false);
const uploadingProgress = ref(0.0);

const submitData = async () => {
  if (isUploading.value) {
    return;
  }

  if (formDataObject.value.images.length === 0 || !formDataObject.value.description || !formDataObject.value.location || formDataObject.value.isSell && !formDataObject.value.price) {
    hardValidate.value = !hardValidate.value;
    return useWebAppHapticFeedback().impactOccurred('heavy');
  }

  useWebAppHapticFeedback().impactOccurred('soft');
  const formData = new FormData();

  formData.append('telegramId', useWebApp().initDataUnsafe.user.id || "-1");
  formData.append('telegramName', `${useWebApp().initDataUnsafe.user.first_name}${useWebApp().initDataUnsafe.user.last_name ? " " + useWebApp().initDataUnsafe.user.last_name : ""}` || "Аноним");

  formDataObject.value.images.forEach((image, index) => {
    const blob = base64ToBlob(image, 'image/png');
    formData.append(`images`, blob, `image_${index}.png`);
  });

  formData.append('location', formDataObject.value.location);
  formData.append('price', formDataObject.value.isSell ? formDataObject.value.price.toString() : "0");
  formData.append('description', formDataObject.value.description);
  formData.append('isSell', formDataObject.value.isSell ? "1" : "0");

  const xhr = new XMLHttpRequest();
  xhr.open("POST", `${process.env.VUE_APP_API_URL}/ad/submit`);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      isUploading.value = true;
      uploadingProgress.value = event.loaded / event.total;
    }
  };

  xhr.onload = () => {
    useWebApp().close();
    analytics.sendEvent('Send button', {});
  };

  xhr.onerror = () => {
    console.error('An error occurred during the upload');
  };

  xhr.send(formData);
};


const base64ToBlob = (base64: string, contentType: string): Blob => {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: contentType});
};

const selectTab = (tab: number) => {
  selectedTab.value = tab;

  formDataObject.value.isSell = tab == 0;
};

onMounted(() => {
    const formData = new FormData();
    formData.append('telegramId', useWebApp().initDataUnsafe.user.id);

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", `${process.env.VUE_APP_API_URL}/user/online`);
    xhr.send(formData);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(theme) + '-theme')
  }, [
    _createVNode(LoadingOverlayComponent, {
      show: isUploading.value,
      progress: uploadingProgress.value
    }, null, 8, ["show", "progress"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "header" }, [
          _createElementVNode("img", {
            src: "/assets/logo.jfif",
            alt: "Логотип",
            style: {"width":"3rem","height":"3rem","border-radius":"100rem"}
          }),
          _createElementVNode("p", null, "Вы размещаете объявление в Scalebid")
        ], -1)),
        _createVNode(TabHeaderComponent, { onTabSelected: selectTab })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ImageUploadComponent, {
          error: hardValidate.value,
          images: formDataObject.value.images,
          "onUpdate:images": _cache[0] || (_cache[0] = ($event: any) => ((formDataObject.value.images) = $event))
        }, null, 8, ["error", "images"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(TabContentComponent, {
          "active-tab": selectedTab.value,
          "hard-validate": hardValidate.value,
          location: formDataObject.value.location,
          "onUpdate:location": _cache[1] || (_cache[1] = ($event: any) => ((formDataObject.value.location) = $event)),
          description: formDataObject.value.description,
          "onUpdate:description": _cache[2] || (_cache[2] = ($event: any) => ((formDataObject.value.description) = $event)),
          price: formDataObject.value.price,
          "onUpdate:price": _cache[3] || (_cache[3] = ($event: any) => ((formDataObject.value.price) = $event))
        }, null, 8, ["active-tab", "hard-validate", "location", "description", "price"]),
        _createVNode(_unref(MainButton), {
          text: "Отправить на проверку",
          onClick: submitData
        })
      ])
    ])
  ], 2))
}
}

})