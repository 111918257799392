import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "image-list"
}
const _hoisted_2 = { class: "images-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]

import {getCurrentInstance, ref, toRefs, watch} from 'vue';
import {Analytics} from "@/analytics/Analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploadComponent',
  props: {
    error: { type: Boolean }
  },
  emits: ['update:images'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const images = ref<string[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);
const props = __props;

const {error} = toRefs(props);
const isError = ref(false);

watch(() => error.value, () => {
  isError.value = true;
});

const triggerUpload = () => {
  fileInput.value?.click();
};

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const files = target.files;

  if (files && files.length > 0 && images.value.length < 7) {
    for (const file of Array.from(files)) {
      if (!file.type.startsWith('image/') || file.type.includes('hei')) continue;
      if (file.size > process.env.VUE_APP_MAX_FILE_SIZE * 1024 * 1024) continue;

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          const img = new Image();
          img.src = e.target.result as string;

          img.onload = () => {
            if (img.width + img.height > 10000 || img.width / img.height > 20) {
              console.warn(`Изображение ${file.name} превышает допустимые размеры и будет пропущено.`);
              return;
            }

            if (images.value.length < 7) {
              images.value.push(e.target?.result as string);
              emit('update:images', images.value);
              analytics.sendEvent("Image", {
                Action: "Add"
              });
            }
          };
        }
      };

      reader.readAsDataURL(file);
    }
  }

  target.value = '';
};



const removeImage = (index: number) => {
  images.value.splice(index, 1);

  analytics.sendEvent("Image", {
    Action: "Delete"
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'upload-area': true, 'center': images.value.length === 0})
  }, [
    (images.value.length === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: triggerUpload,
          class: _normalizeClass(["upload-button full", {error: isError.value}])
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { style: {"width":"1.4rem","height":"1.4rem"} }, [
            _createElementVNode("svg", {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("line", {
                y1: "11.8",
                x2: "24",
                y2: "11.8",
                stroke: "currentColor"
              }),
              _createElementVNode("line", {
                x1: "12.2",
                y1: "0",
                x2: "12.2",
                y2: "24",
                stroke: "currentColor"
              })
            ])
          ], -1),
          _createElementVNode("span", null, "Добавить фотографии", -1)
        ]), 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", {
            onClick: triggerUpload,
            class: "upload-button small"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { style: {"width":"1.4rem","height":"1.4rem"} }, [
              _createElementVNode("svg", {
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("line", {
                  y1: "11.8",
                  x2: "24",
                  y2: "11.8",
                  stroke: "currentColor"
                }),
                _createElementVNode("line", {
                  x1: "12.2",
                  y1: "0",
                  x2: "12.2",
                  y2: "24",
                  stroke: "currentColor"
                })
              ])
            ], -1)
          ]), 512), [
            [_vShow, images.value.length < 7]
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (image, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "image-item"
              }, [
                _createElementVNode("img", {
                  src: image,
                  alt: "Uploaded image"
                }, null, 8, _hoisted_3),
                _createElementVNode("button", {
                  onClick: ($event: any) => (removeImage(index)),
                  class: "remove-btn"
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    src: "/assets/close.svg",
                    alt: "Закрыть",
                    style: {"width":"2.2rem","height":"2.2rem"}
                  }, null, -1)
                ]), 8, _hoisted_4)
              ]))
            }), 128))
          ])
        ])),
    _createElementVNode("input", {
      type: "file",
      ref_key: "fileInput",
      ref: fileInput,
      onChange: onFileChange,
      accept: "image/*",
      style: {"display":"none"}
    }, null, 544)
  ], 2))
}
}

})