import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "categories" }
const _hoisted_2 = ["onClick"]

import {getCurrentInstance, ref} from 'vue';
import {Analytics} from "@/analytics/Analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabHeaderComponent',
  emits: ["tabSelected"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const selectedCategory = ref(0);

const selectCategory = (category: number) => {
  emit("tabSelected", category);
  selectedCategory.value = category;

  analytics.sendEvent("Tabs", {
    Tab: category === 0 ? "Sale" : "Buy"
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['Продаю', 'Ищу'], (category, index) => {
      return _createElementVNode("button", {
        key: index,
        class: _normalizeClass({ active: selectedCategory.value === index }),
        onClick: ($event: any) => (selectCategory(index))
      }, _toDisplayString(category), 11, _hoisted_2)
    }), 64))
  ]))
}
}

})