import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {ref, toRefs} from "vue";
import {useWebAppTheme, useWebAppViewport} from "vue-tg";

const descriptionDefault = "Обратитесь в @yscanchat, если считаете, что это ошибка";


export default /*@__PURE__*/_defineComponent({
  __name: 'RestrictedView',
  props: {
    description: {}
  },
  setup(__props: any) {

const theme = useWebAppTheme().colorScheme;
const viewportHeight = useWebAppViewport().viewportStableHeight;
const props = __props;
const {description} = toRefs(props);

const descriptionResult = ref("");
descriptionResult.value = description.value == "" ? descriptionDefault : description.value

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(theme) + '-theme container blocked-page')
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: "/assets/ban.svg",
      alt: "Blocked Image",
      class: "blocked-page__image"
    }, null, -1),
    _createElementVNode("h1", { class: "blocked-page__title" }, "Доступ заблокирован", -1),
    _createElementVNode("p", { class: "blocked-page__subtitle" }, "Кажется, кто-то допизделся", -1)
  ]), 2))
}
}

})