import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "overlay"
}
const _hoisted_2 = { class: "progress-container" }
const _hoisted_3 = { class: "progress-bar" }




export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingOverlayComponent',
  props: {
    show: { type: Boolean },
    progress: {}
  },
  setup(__props: any) {

const props = __props;

if (props.progress < 0 || props.progress > 1) {
  console.warn('Progress should be between 0 and 1');
}

return (_ctx: any,_cache: any) => {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "title" }, "Загружаем фотографии", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "subtitle" }, "Нужно немного подождать", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "progress-fill",
              style: _normalizeStyle({ width: _ctx.progress * 100 + '%' })
            }, null, 4)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})