import { useSlots as _useSlots, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "placeholder", "maxlength", "value", "disabled"]
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { class: "error-message" }
const _hoisted_6 = { class: "char-count" }

import {ref, toRefs, watch} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TextareaComponent',
  props: {
    id: {},
    maxLength: {},
    labelText: {},
    placeholder: {},
    emptyMessage: {},
    modelValue: {},
    hardValidate: { type: Boolean },
    isDisabled: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const {id, maxLength, labelText, placeholder, emptyMessage, modelValue, hardValidate, isDisabled} = toRefs(props);
const isEmpty = ref(false);
const errorMessage = ref("");

const slots = _useSlots();

const validate = () => {
  isEmpty.value = modelValue.value.trim() === '';

  if (!isEmpty.value) {
    errorMessage.value = "";
  } else {
    errorMessage.value = emptyMessage.value;
  }
};

watch(() => hardValidate.value, () => validate());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: _unref(id) }, _toDisplayString(_unref(labelText)), 9, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass({container: true, error: isEmpty.value})
    }, [
      _createElementVNode("textarea", {
        id: _unref(id),
        class: _normalizeClass({error: isEmpty.value, slotted: slots.subspace}),
        placeholder: _unref(placeholder),
        maxlength: _unref(maxLength),
        value: _unref(modelValue),
        disabled: _unref(isDisabled),
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onBlur: validate
      }, null, 42, _hoisted_3),
      _renderSlot(_ctx.$slots, "subspace")
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(errorMessage.value), 1),
      _createElementVNode("span", _hoisted_6, _toDisplayString((_unref(maxLength) - _unref(modelValue).length)) + " / " + _toDisplayString(_unref(maxLength)), 1)
    ])
  ]))
}
}

})