<template>
  <div class="input-group">
    <label :for="id">{{ labelText }}</label>

    <div :class="{container: true, error: isEmpty}">
      <textarea :id="id"
                :class="{error: isEmpty, slotted: slots.subspace}"
                :placeholder="placeholder"
                :maxlength="maxLength"
                :value="modelValue"
                :disabled="isDisabled"
                @input="$emit('update:modelValue', $event.target.value)"
                @blur="validate"/>

      <slot name="subspace"></slot>
    </div>

    <div class="footer">
      <span class="error-message">{{ errorMessage }}</span>
      <span class="char-count">{{ (maxLength - modelValue.length) }} / {{ maxLength }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineProps, ref, toRefs, defineSlots, watch} from "vue";

const props = defineProps<{
  id: string,
  maxLength: number,
  labelText: string,
  placeholder: string,
  emptyMessage: string,
  modelValue: string,
  hardValidate: boolean,
  isDisabled: boolean
}>();

const {id, maxLength, labelText, placeholder, emptyMessage, modelValue, hardValidate, isDisabled} = toRefs(props);
const isEmpty = ref(false);
const errorMessage = ref("");

const slots = defineSlots();

const validate = () => {
  isEmpty.value = modelValue.value.trim() === '';

  if (!isEmpty.value) {
    errorMessage.value = "";
  } else {
    errorMessage.value = emptyMessage.value;
  }
};

watch(() => hardValidate.value, () => validate());
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .input-group {
    .container {
      border: 1px solid themed("accent-gray-5");

      &:focus-within {
        border-color: themed("button") !important;
      }

      &.error {
        border-color: themed("accent-red");
      }
    }

    label {
      color: themed('text');
    }

    textarea {
      background-color: themed("accent-gray-6");
      color: themed('text');

      &::placeholder {
        color: themed("accent-gray");
      }
    }

    .footer {
      .error-message {
        color: themed("accent-red");
      }

      .char-count {
        color: themed("accent-gray");
      }
    }
  }
}

.input-group {
  margin-bottom: 1.5rem;

  .container {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;

    border: 1px solid themed("accent-gray-5");
    border-radius: .65rem;
  }

  label {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 0.3rem;
  }

  label, .footer {
    margin-left: 0.5rem;
  }

  textarea {
    width: 100%;
    padding: 0.55rem 0.75rem;
    font-size: 1.05rem;
    border-radius: .65rem;
    box-sizing: border-box;
    height: 100px;
    resize: none;

    outline: none;
    border: none;

    &.slotted {
      border-radius: .65rem .65rem 0 0;
    }

    &:focus {
      outline: none;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    margin-top: 0.35rem;
    margin-left: 0.5rem;

    font-size: 0.875rem;

    .char-count {
      text-align: right;
    }
  }

  .textarea-footer .error-message:empty {
    visibility: hidden;
  }

  .textarea-footer .error-message:not(:empty) {
    visibility: visible;
  }
}
</style>