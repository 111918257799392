import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {useWebApp} from 'vue-tg';

import ErrorView from "@/views/ErrorView.vue";
import {getCurrentInstance, onBeforeMount, onBeforeUnmount, Ref, ref} from "vue";
import axios from "axios";
import {Analytics} from "@/analytics/Analytics";
import {AmplitudeAnalytics} from "@/analytics/AmplitudeAnalytics";
import PreloaderView from "@/views/PreloaderView.vue";
import RestrictedView from "@/views/RestrictedView.vue";
import {UserProperties} from "@/interfaces/users/UserPropertiesInterface";
import {useSettingsStore} from "@/stores/settingsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const instance = getCurrentInstance();

const isDataLoaded = ref(false);
const isServerError = ref(false);

const isRestricted = ref(false);
const restrictedDescription = ref("");

onBeforeMount(async () => {
  const userProperties: Ref<UserProperties | undefined | null> = ref(null);

  const userData = getTelegramUserData();
  const analytics = setupAnalytics(process.env.VUE_APP_AMPLITUDE_TOKEN, userData);

  try {
    const responseUsersProperties = await getResponseContent(process.env.VUE_APP_USERSPROPERTIES_URL);
    isServerError.value = !responseUsersProperties.data;

    userProperties.value = (responseUsersProperties.data.filter((x: UserProperties) => x.telegram_id == userData.id))[0];


    const {fetchSettings} = useSettingsStore();
    await fetchSettings();

    isDataLoaded.value = true;
  } catch (e) {
    isDataLoaded.value = true;
  }

  if (userProperties.value != null) {
    isRestricted.value = true;
    restrictedDescription.value = userProperties.value.public_comment;

    if (!userProperties.value.is_trace)
      analytics.pause();

    analytics.sendEvent('Ban', {});
  }

  if (instance && instance.appContext) {
    instance.appContext.config.globalProperties.analytics = analytics;
  }
});

onBeforeUnmount(() => {
  localStorage.removeItem('tab');
});

async function getResponseContent(url: string) {
  return await axios.get(url, {
    headers: {'Content-Type': 'application/json'}
  });
}
function getTelegramUserData() {
  const params = new URLSearchParams(useWebApp().initData);
  const data = Object.fromEntries(params);

  return JSON.parse(data.user);
}
function setupAnalytics(token: string, userData: object) {
  const analytics: Analytics = new AmplitudeAnalytics(token);

  analytics.setUser(userData.id);
  analytics.setLanguage(userData.language_code);
  analytics.setIdentifyField("Telegram username", userData.username);

  return analytics;
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (isDataLoaded.value)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (isServerError.value)
          ? (_openBlock(), _createBlock(ErrorView, {
              key: 0,
              description: "Ошибка сервера"
            }))
          : (!_unref(useWebApp)().initData)
            ? (_openBlock(), _createBlock(ErrorView, {
                key: 1,
                description: "Используйте Telegram клиент"
              }))
            : (isRestricted.value)
              ? (_openBlock(), _createBlock(RestrictedView, {
                  key: 2,
                  description: restrictedDescription.value
                }, null, 8, ["description"]))
              : (_openBlock(), _createBlock(_component_router_view, { key: 3 }))
      ], 64))
    : (_openBlock(), _createBlock(PreloaderView, { key: 1 }))
}
}

})