import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import VueTelegram from 'vue-tg'
import {createPinia} from "pinia";

const app = createApp(App);

app.use(createPinia());
app.use(VueTelegram);
app.use(router).mount('#app');
