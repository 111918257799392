<template>
  <div class="improve-text">
    <span :class="{'text-hidden': !!enhancedText.length}">{{ !enhancedText.length ? symbolsRemain() : "&nbsp;" }}</span>

    <button v-if="!originalText.length"
            class="btn btn-improve"
            :disabled="count > 0 && enhancedText.length == 0 || isLoading"
            @click="enhanceText">
      <img src="/assets/ai.svg" alt="Улучшить текст">
      <span>{{ enhancedText.length == 0 ? "Улучшить текст" : "Улучшенный текст" }}</span>
    </button>

    <button class="btn btn-revert"
            @click="revertText" v-else>
      <img src="/assets/ai-revert.svg" alt="Вернуть оригинал">
      Вернуть оригинал
    </button>
  </div>
</template>

<script setup lang="ts">
import {defineProps, defineEmits, ref, toRefs, watch, getCurrentInstance} from "vue";
import {useTextEnhanceStore} from "@/stores/textEnhanceStore";
import {storeToRefs} from "pinia";
import {useSettingsStore} from "@/stores/settingsStore";
import {Analytics} from "@/analytics/Analytics";

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const props = defineProps<{
  text: string,
}>();
const emit = defineEmits(["enhancedText", "changeDisabling"]);
const store = useTextEnhanceStore();
const {setTextEnhance} = useSettingsStore();

const {
  originalText,
  enhancedText,
  isLoading,
} = storeToRefs(store);

const {
  setOriginalText,
  setEnhancedText,
  setLoading
} = store;

const {text} = toRefs(props);

const count = ref(40 - text.value.length);

const symbolsRemain = () => {
  if (count.value <= 0) {
    return "Можно улучшить текст";
  }

  const result = `Ещё ${count.value} `;

  const pluralRules = new Intl.PluralRules('ru-RU');
  const pluralForm = pluralRules.select(count.value);

  switch (pluralForm) {
    case 'one':
      return result + 'символ';
    case 'few':
      return result + 'символа';
    default:
      return result + 'символов';
  }
}

const enhanceText = async () => {
  if (enhancedText.value) {
    analytics.sendEvent("AI Text", {
      Status: "Back to AI"
    });

    setOriginalText(text.value);
    return emit("enhancedText", enhancedText.value);
  }

  analytics.sendEvent("AI Text", {
    Status: "First time"
  });

  setLoading(true);

  let result;

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/ad/enhance/text`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({text: text.value})
    });
    if (!response.ok) {
      setTextEnhance(false);
      setLoading(false);
      return;
    }

    result = await response.json();
  } catch (e) {
    setTextEnhance(false);
    setLoading(false);
    return;
  }

  setEnhancedText(result.enhancedText);

  setOriginalText(text.value);
  emit("enhancedText", enhancedText.value);
  setLoading(false);
}

const revertText = () => {
  analytics.sendEvent("AI Text", {
    Status: "Back to original"
  });

  emit("enhancedText", originalText.value);

  setOriginalText("");
}

watch(() => text.value, () => count.value = 40 - text.value.length);
watch(() => isLoading, () => emit("changeDisabling"));
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .improve-text {
    border-top: 1px solid themed("accent-gray-5") !important;

    background-color: themed("accent-gray-6");
    color: themed("accent-gray");
  }

  .btn {
    color: themed("accent-white");

    &-revert {
      background: themed("accent-black");
    }

    &:disabled {
      background: themed("accent-gray");
    }
  }
}

.improve-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.8rem;
  border-radius: 0 0 .65rem .65rem;
  font-size: 0.85rem;
}

.btn {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 100rem;
  font-weight: 400;
  white-space: nowrap;

  padding: 0.3rem 0.7rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;

  &-improve {
    position: relative;
    overflow: hidden;
    background: #FF2D55;

    &[disabled]::before {
      animation: none;
      background: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 300%;
      height: 100%;
      background: linear-gradient(90deg, #FF9500, rgba(255, 149, 0, 0), #FF9500, rgba(255, 149, 0, 0));
      z-index: 0;
      animation: moveGradient 5s infinite linear;
    }

    & > * {
      position: relative;
      z-index: 1;
    }

    @keyframes moveGradient {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-66.66%);
      }
    }
  }

  &-revert {
    img {
      width: 0.6rem !important;
    }
  }

  img {
    width: 0.9rem;
  }
}

.text-hidden {
  visibility: hidden;
}

</style>