import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {useWebAppTheme, useWebAppViewport} from "vue-tg";


export default /*@__PURE__*/_defineComponent({
  __name: 'PreloaderView',
  setup(__props) {

const theme = useWebAppTheme().colorScheme;
const viewportHeight = useWebAppViewport().viewportStableHeight;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('container ' + _unref(theme) + '-theme')
  }, [
    _createElementVNode("div", {
      class: "lds-ellipsis",
      style: _normalizeStyle('top: ' + _unref(viewportHeight) * 0.5 + 'px')
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "lds-child" }, null, -1),
      _createElementVNode("div", { class: "lds-child" }, null, -1),
      _createElementVNode("div", { class: "lds-child" }, null, -1),
      _createElementVNode("div", { class: "lds-child" }, null, -1)
    ]), 4),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "content" }, null, -1))
  ], 2))
}
}

})