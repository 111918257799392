import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "type", "placeholder", "value"]

import {getCurrentInstance, ref, toRefs, watch} from "vue";
import {Analytics} from "@/analytics/Analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputComponent',
  props: {
    id: {},
    type: {},
    labelText: {},
    placeholder: {},
    emptyMessage: {},
    modelValue: {},
    hardValidate: { type: Boolean }
  },
  setup(__props: any) {

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const props = __props;

const {id, type, labelText, placeholder, emptyMessage, modelValue, hardValidate} = toRefs(props);
const isEmpty = ref(false);

const validate = () => {
  isEmpty.value = type.value !== "number" ? modelValue.value.trim() === '' :
      parseInt(modelValue.value) <= 0 || modelValue.value.length == 0;
};

watch(() => hardValidate.value, () => validate());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: _unref(id) }, _toDisplayString(_unref(labelText)), 9, _hoisted_2),
    _createElementVNode("input", {
      id: _unref(id),
      class: _normalizeClass({error: isEmpty.value}),
      type: _unref(type),
      placeholder: _unref(placeholder),
      value: _unref(modelValue),
      onInput: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('update:modelValue', $event.target.value) &&
                   _unref(analytics).sendEvent('Input',{
                              Field: _unref(id)
                            });}),
      onBlur: validate
    }, null, 42, _hoisted_3),
    _createElementVNode("span", {
      class: _normalizeClass({'error-message': true, 'visible': isEmpty.value, 'hidden': !isEmpty.value })
    }, _toDisplayString(_unref(emptyMessage)), 3)
  ]))
}
}

})