import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "description" }

import {toRefs} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorView',
  props: {
    description: {}
  },
  setup(__props: any) {

const props = __props;

const { description } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title" }, "Ошибка", -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(description)), 1)
  ]))
}
}

})