import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useTextEnhanceStore = defineStore('textEnhance', () => {
    const originalText = ref<string>('');
    const enhancedText = ref<string>('');
    const isLoading = ref<boolean>(false);

    const setOriginalText = (text: string) => {
        originalText.value = text;
    };

    const setEnhancedText = (text: string) => {
        enhancedText.value = text;
    };

    const setLoading = (status: boolean) => {
        isLoading.value = status;
    };

    const reset = () => {
        originalText.value = '';
        enhancedText.value = '';
        isLoading.value = false;
    };

    return {
        originalText,
        enhancedText,
        isLoading,
        setOriginalText,
        setEnhancedText,
        setLoading,
        reset,
    };
});
