import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "improve-text" }
const _hoisted_2 = ["disabled"]

import {ref, toRefs, watch, getCurrentInstance} from "vue";
import {useTextEnhanceStore} from "@/stores/textEnhanceStore";
import {storeToRefs} from "pinia";
import {useSettingsStore} from "@/stores/settingsStore";
import {Analytics} from "@/analytics/Analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'AiBlockComponent',
  props: {
    text: {}
  },
  emits: ["enhancedText", "changeDisabling"],
  setup(__props: any, { emit: __emit }) {

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const props = __props;
const emit = __emit;
const store = useTextEnhanceStore();
const {setTextEnhance} = useSettingsStore();

const {
  originalText,
  enhancedText,
  isLoading,
} = storeToRefs(store);

const {
  setOriginalText,
  setEnhancedText,
  setLoading
} = store;

const {text} = toRefs(props);

const count = ref(40 - text.value.length);

const symbolsRemain = () => {
  if (count.value <= 0) {
    return "Можно улучшить текст";
  }

  const result = `Ещё ${count.value} `;

  const pluralRules = new Intl.PluralRules('ru-RU');
  const pluralForm = pluralRules.select(count.value);

  switch (pluralForm) {
    case 'one':
      return result + 'символ';
    case 'few':
      return result + 'символа';
    default:
      return result + 'символов';
  }
}

const enhanceText = async () => {
  if (enhancedText.value) {
    analytics.sendEvent("AI Text", {
      Status: "Back to AI"
    });

    setOriginalText(text.value);
    return emit("enhancedText", enhancedText.value);
  }

  analytics.sendEvent("AI Text", {
    Status: "First time"
  });

  setLoading(true);

  let result;

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/ad/enhance/text`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({text: text.value})
    });
    if (!response.ok) {
      setTextEnhance(false);
      setLoading(false);
      return;
    }

    result = await response.json();
  } catch (e) {
    setTextEnhance(false);
    setLoading(false);
    return;
  }

  setEnhancedText(result.enhancedText);

  setOriginalText(text.value);
  emit("enhancedText", enhancedText.value);
  setLoading(false);
}

const revertText = () => {
  analytics.sendEvent("AI Text", {
    Status: "Back to original"
  });

  emit("enhancedText", originalText.value);

  setOriginalText("");
}

watch(() => text.value, () => count.value = 40 - text.value.length);
watch(() => isLoading, () => emit("changeDisabling"));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass({'text-hidden': !!_unref(enhancedText).length})
    }, _toDisplayString(!_unref(enhancedText).length ? symbolsRemain() : " "), 3),
    (!_unref(originalText).length)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-improve",
          disabled: count.value > 0 && _unref(enhancedText).length == 0 || _unref(isLoading),
          onClick: enhanceText
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: "/assets/ai.svg",
            alt: "Улучшить текст"
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_unref(enhancedText).length == 0 ? "Улучшить текст" : "Улучшенный текст"), 1)
        ], 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "btn btn-revert",
          onClick: revertText
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: "/assets/ai-revert.svg",
            alt: "Вернуть оригинал"
          }, null, -1),
          _createTextVNode(" Вернуть оригинал ")
        ])))
  ]))
}
}

})